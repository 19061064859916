<template>
  <div class="unfinished-drafts">
    <div class="panel">
      <div id="drafts-header">
        <v-btn @click="clearList">Clear</v-btn>
      </div>
      <div>
        <DraftsTable />
      </div>
    </div>
  </div>
</template>

<script>
import DraftsTable from "@/components/nonPageComponents/DraftsTable";
import { mapGetters } from "vuex";
export default {
  components: {
    DraftsTable
  },
  computed: {
    ...mapGetters({
      draftList: "unfinishedDrafts"
    })
  },
  methods: {
    clearList() {
      this.$store.commit("unfinishedDrafts", []);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

::v-deep .b-table-sticky-header {
  max-height: calc(100vh - #{$nav-height} - #{$panel-margin}* 2 - 100px);
}

#drafts-header {
  display: flex;
  justify-content: flex-end;
}
</style>
